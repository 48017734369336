import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';
import { CATEGORY_WITH_CONTEXT_FRAGMENT } from '@/graphql/_Fragments/Category/WithContext';

export const SESSION_SCHEDULE_OF_EVENT_FRAGMENT = `
  fragment sessionScheduleOfEventFragment on Session {
    uid
    name
    startTime
    startTimestamp
    endTime
    endTimestamp
    categoriesInContext(context:"session_flag") {
    ...categoryWithContextFragment
    }
    speakerRoles {
      ...speakerRoleForSessionFragment
    }
    geozonesOfEdition: _geozonesOfEdition {
      exhibitHall {
        name
      }
    }
    _isInAgenda(myUid: "%authUser%")
  }
  ${CATEGORY_WITH_CONTEXT_FRAGMENT}
  ${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
  `;
